<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header-no-bg" style="background-image: url(../img/cover_top_up_smart.jpg)">
    </div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        For any recipient in Zimbabwe, topup /buy their local numbers airtime or
        data bundles and get to perform this top up of any cellular or telephone
        services in real time
      </h3>

      <div class="">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="text-bold ls-nav-head nav-link active" id="nav-mobile-recharge-tab" data-toggle="tab"
              href="#nav-mobile-recharge" role="tab" aria-controls="nav-mobile-recharge" aria-selected="true"><b>Mobile
                Recharge</b></a>
            <a class="text-bold ls-nav-head nav-link" id="nav-voucher-recharge-tab" data-toggle="tab"
              href="#nav-voucher-recharge" role="tab" aria-controls="nav-voucher-recharge" aria-selected="true"><b>Voucher
                Recharge</b></a>
            <a class="text-bold ls-nav-head nav-link" id="nav-telone-bundles-tab" data-toggle="tab"
              href="#nav-telone-bundles" role="tab" aria-controls="nav-telone-bundles" aria-selected="false"><b>ADSL
                Recharge</b></a>
          </div>
        </nav>
        <div class="tab-content ls-tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-mobile-recharge" role="tabpanel"
            aria-labelledby="nav-mobile-recharge-tab">
            <MobileRecharge :balance="balance"></MobileRecharge>
          </div>
          <div class="tab-pane fade" id="nav-voucher-recharge" role="tabpanel" aria-labelledby="nav-voucher-recharge-tab">
            <VoucherRecharge></VoucherRecharge>
          </div>
          <div class="tab-pane fade" id="nav-telone-bundles" role="tabpanel" aria-labelledby="nav-telone-bundles-tab"
            style="text-align: center">
            <TeloneBundles :balance="balance"></TeloneBundles>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MobileRecharge from "./MobileRecharge";
import VoucherRecharge from "./VoucherRecharge";
// import RechargeZesa from "./RechargeZesa";
import TeloneBundles from "./TeloneBundles";
import axios from "axios";
export default {
  name: "TopUp",
  components: { TeloneBundles, /*RechargeZesa,*/ MobileRecharge, VoucherRecharge },
  data() {
    return {
      balance: 0,
    }
  },
  mounted() {
    this.checkBalance();
  },
  methods: {
    checkBalance() {
      this.isLoading = true;
      axios.get("hot/balance").then((response) => {
        this.isLoading = false;
        this.balance = response.data.WalletBalance;
      })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  }
};

</script>
