<template>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
        <div class="col-xl-6 col-lg-6 col-md-8 col-12">
            <div class="ls-center">
                <h3 style="text-align: center;">Airtime Prepaid Voucher</h3>
            </div>
            <form class="ls-form">
                <div class="form-group">
                    <label for="catDescription">Enter Recharge Amount</label>
                    <input class="form-control" type="number" v-model="form.amount" id="amount" placeholder="" />
                    <small v-if="errors && errors.amount" class="mobile_error text-danger">
                        {{ errors.amount }}
                    </small>
                </div>
                <!-- <div class="form-group">
                    <label for="voucherShop">Select Recharge Amount</label>
                    <select class="form-control" id="voucherShop" v-model="form.amount">
                        <option selected value="1">$1</option>
                        <option value="5">$5</option>
                        <option value="10">$10</option>
                    </select>
                    <small v-if="errors && errors.amount" class="mobile_error text-danger">
                        {{ errors.amount }}
                    </small>
                </div> -->
                <div class="form-group">
                    <label for="voucherShop">Select network</label>
                    <select class="form-control" id="voucherShop" v-model="form.network">
                        <option selected value="Econet">Econet</option>
                        <option value="Netone">NetOne</option>
                        <option value="Telecel">Telecel</option>
                    </select>
                    <small v-if="errors && errors.network" class="mobile_error text-danger">
                        {{ errors.network }}
                    </small>
                </div>
                <div class="form-group form-check">
                    <input required type="checkbox" class="form-check-input" id="agreeToTerms" v-model="agreeToTerms" />
                    <label class="form-check-label" for="agreeToTerms">
                        I have Read the Terms and Agreements</label>
                    <small v-if="errors && errors.terms" class="terms_error text-danger">
                        {{ errors.terms }}
                    </small>
                </div>
                <div class="justify-content-between mb-4 mt-4">
                    <div>
                        &nbsp;&nbsp;
                        <button v-if="auth.isAuthenticated" :disabled="isLoading" type="submit"
                            class="btn btn-primary ls-submit-button" @click.prevent="checkAvailability()">
                            <i v-if="!isLoading" class="icon-cart-add"></i>
                            {{ isLoading ? "Processing..." : "Add to cart" }}
                            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                            <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
                        </button>
                        <router-link v-else :to="{ name: 'login' }">
                            <button type="submit" class="btn btn-primary ls-submit-button">
                                <i v-if="!isLoading" class="icon-cart-add"></i>
                                Add to Cart
                            </button>
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
    name: "DialFrom263",
    data() {
        return {
            countries: [],
            form: {
                amount: "",
                network: "",
                phone: "",
            },
            agreeToTerms: false,
            isLoading: false,
            voucherShop: "",
            errors: {
                amount: "",
                phone: "",
                network: "",
                terms: "",
            },
        };
    },
    computed: {
        ...mapState({
            auth: (state) => state.auth,
            cart: (state) => state.cart,
        }),
    },
    mounted() {
        this.setUpAccountDetails();
    },
    methods: {
        setUpAccountDetails() {
            if (this.auth.isAuthenticated) {
                this.form.phone = this.auth.user.phone_number;
            }
        },

        async checkAvailability() {
            try {
                if (!this.isValid()) {
                    return;
                }

                this.isLoading = true;
                const response = await axios.post("vouchers/airtime/check", this.form);
                this.isLoading = false;

                if (!response.data.status) {
                    this.$toasted.error(response.data.message);
                    return;
                }

                this.addToCart();

            } catch (error) {
                this.isLoading = false;
                console.error("Error checking availability:", error);
            }
        },


        isValid() {
            let isValidForm = true;
            if (this.form.amount === "") {
                this.errors["amount"] = "Airtime amount is required";
                isValidForm = false;
                this.isLoading = false;
            }
            if (this.form.network === "") {
                this.errors["network"] = "Mobile network is required";
                isValidForm = false;
                this.isLoading = false;
            }
            if (!this.agreeToTerms) {
                this.errors["terms"] = "Please agree to terms and conditions";
                isValidForm = false;
                this.isLoading = false;
            }
            return isValidForm;
        },

        addToCart() {
            if (this.isValid()) {
                this.form.phone = this.form.phone.replace(/^0+/, '');
                let voucherName = "Airtime voucher";
                const quantity = 1;
                const product = {
                    id: this.generateUUID(),
                    name: voucherName,
                    quantity: quantity,
                    phone: this.form.phone,
                    price: this.form.amount,
                    total: this.form.amount * quantity,
                    props: {
                        network: this.form.network,
                    },
                    type: "airtime-voucher",
                    shop: 2,
                };

                console.log("Voucher");
                console.log(product);
                this.$store.dispatch("cart/addToCart", {
                    product: product,
                    quantity: 1,
                });

                // this.$toasted.success("DialFrom263 added to cart");
                this.$toasted.show(
                    "Airtime voucher added to cart",
                    {
                        duration: 20000,
                        action: [
                            {
                                text: 'Checkout',
                                push: {
                                    name: 'cart'
                                }
                            },
                            {
                                text: 'Continue shopping',
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                }

                            }
                        ]
                    }
                )
            }
        },

        generateUUID() {
            // Public Domain/MIT
            let d = new Date().getTime(); //Timestamp
            let d2 =
                (typeof performance !== "undefined" &&
                    performance.now &&
                    performance.now() * 1000) ||
                0; //Time in microseconds since page-load or 0 if unsupported
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
                c
            ) {
                let r = Math.random() * 16; //random number between 0 and 16
                if (d > 0) {
                    //Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {
                    //Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            });
        },
    },
};
</script>