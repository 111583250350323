<template>
  <div class="ls-airtime-card">
    <h1 class="ls-page-header-title" style="text-align: center; font-size: 3.5rem; padding: 5px 0px;">
      {{ bundle.priceField | toCurrency }}
    </h1>
    <h4 class="ps-product__price" style="text-align: center;">
      {{ bundle.nameField }}
    </h4>
    <div class="ps-product__info">
      <div class="ps-product__desc">
        {{ bundle.descriptionField }}
      </div>
      <button type="submit" class="btn btn-primary ls-button" @click.prevent="addToCart()">
        Add to cart
        <span v-if="isBusy" class="spinner-border text-dark" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TeloneBundle",
  props: {
    bundle: Object,
    balance: Number,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      modifiedBalance: this.balance,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {

    addToCart() {
      // return console.log(this.modifiedBalance);
      if (this.balance >= this.bundle.priceField) {
        this.loading = true;
        const quantity = 1;
        const product = {
          id: this.bundle.productIdField,
          name: this.bundle.nameField,
          quantity: quantity,
          price: this.bundle.priceField,
          total: this.bundle.priceField * quantity,
          shop: 2,
          type: "hot-recharge",
          props: {
            'product_code': this.bundle.productIdField,
            'network': 'Telone USD',
          }
        };

        this.$store.dispatch("cart/addToCart", {
          product: product,
          quantity: 1,
        });

        this.modifiedBalance -= this.bundle.priceField;

        // this.$toasted.show("Data bundles added to cart");
        this.$toasted.show(
          "Data bundles added to cart",
          {
            duration: 20000,
            action: [
              {
                text: 'Checkout',
                push: {
                  name: 'cart'
                }
              },
              {
                text: 'Continue shopping',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }

              }
            ]
          }
        );
        // this.$emit('updateBalance', this.modifiedBalance);
      } else {
        return this.$toasted.show(
          "Sorry service currently unavailable please try again later",
          {
            duration: 20000,
          }
        );
      }

    },

  },
};
</script>

<style scoped></style>
