<template>
  <div class="ls-airtime-card">
    <h1 class="ls-page-header-title" style="text-align: center; font-size: 3.5rem; padding: 5px 0px">
      ${{ dataBundle.Amount / 100 }}
    </h1>
    <h4 class="ps-product__price" style="text-align: center">
      {{ dataBundle.Name.replace(/\$.*$/, "") + "(" + this.topupType + ")" }}
    </h4>
    <div class="ps-product__info">
      <div class="ps-product__desc">
        {{ dataBundle.Description }}
      </div>
      <button type="submit" class="btn btn-primary ls-button" @click.prevent="addToCheckout()">
        Add to cart
        <span v-if="isBusy" class="spinner-border text-dark" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DataBundle",
  props: {
    dataBundle: Object,
    recepient: String,
    balance: Number,
    topupType: String,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      addPhoneNumber: false,
      modifiedBalance: this.balance,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    addToCheckout() {
      const amount = this.dataBundle.Amount / 100;
      const fees = this.topupType === "delayed" ? 0.00 : 0.30;
      const quantity = 1;
      const total = (amount * quantity + fees).toFixed(2);

      const id = this.topupType === "instant" ? this.dataBundle.BundleId : this.dataBundle.BundleId * 2;

      if (this.topupType === "instant") {
        this.$toasted.show(
          "Sorry service is currently unavailable. Please try again later",
          { duration: 20000 }
        );
      }

      if (this.balance >= amount) {
        const isEconet = /2637|07[7-8][0-9]{7}$/.test(this.recepient) && this.dataBundle.Network === 'Econet USD';
        const isNetone = /26371|071[0-9]{7}$/.test(this.recepient) && this.dataBundle.Network === 'Netone USD';
        const networkOp = isEconet || isNetone;

        if (networkOp) {
          this.loading = true;

          const product = {
            id,
            name: this.dataBundle.Name + "(" + this.topupType + ")",
            quantity,
            price: amount,
            total,
            type: "hot-recharge",
            shop: 2,
            props: {
              product_code: this.dataBundle.ProductCode,
              network: this.dataBundle.Network,
              validity_period: this.dataBundle.ValidityPeriod,
              brand_id: this.dataBundle.BrandId,
              phone_number: this.recepient,
              topupType: this.topupType,
              fees,
            },
          };

          this.$store.dispatch("cart/addToCart", { product, quantity });
          this.modifiedBalance -= amount;

          this.$toasted.show(
            "Data bundles added to cart.",
            {
              duration: 20000,
              action: [
                {
                  text: 'Checkout',
                  push: { name: 'cart' },
                },
                {
                  text: 'Continue shopping',
                  onClick: (e, toastObject) => toastObject.goAway(0),
                },
              ],
            }
          );
        } else {
          this.$toasted.error("Please enter the correct recipient phone number (Econet or Netone)");
        }
      } else {
        this.$toasted.show(
          "Sorry service is currently unavailable. Please try again later",
          { duration: 20000 }
        );
      }
    },

    addToCart() {
      this.addPhoneNumber = true;
      alert("" + this.recepient);
    },
  },
};
</script>

<style scoped></style>
