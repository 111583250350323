<template>
  <form class="ls-form">
    <h3 style="text-align: center">TelOne Bundles</h3>
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
        <div class="form-group">
          <label for="bundle_type">Select Bundle Type</label>
          <select class="form-control" id="bundle_type" @change="filterBundles($event)" v-model="bundleType">
            <option v-for="bundleType in bundleTypes" :key="bundleType">
              {{ bundleType }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="bundle_type">Select Bundle</label>
          <select class="form-control" id="bundle_type" @change="selectedBundle($event)" v-model="selectedBundleId">
            <option v-for="bundle in teloneBundles" :key="bundle.productIdField" :value="bundle.productIdField">
              {{ bundle.nameField }} {{ bundle.priceField | toCurrency }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
        <div class="form-group">
          <label>Bundle Details</label>
          <div class="row ls-bundles-container">
            <div class="col-xl-3 col-lg-4 col-md-6 col-12 equal-height" v-if="teloneBundles.length > 0">
              <TeloneBundle :bundle="selectedBundleOb" :balance="balance"></TeloneBundle>
            </div>
            <div v-if="teloneBundles.length === 0" class="col-xl-12">
              <div class="p-4 text-info">No bundles found</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import TeloneBundle from "./TeloneBundle";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "TeloneBundles",
  components: { TeloneBundle },
  props: {
    balance: Number,
  },
  data() {
    return {
      bundles: [],
      bundleTypes: [],
      teloneBundles: [],
      selectedBundleOb: {},
      selectedBundleId: 0,
      meta: null,
      isLoading: false,
      isBusy: false,
      isDeleting: false,
      bundleType: null,
    };
  },
  mounted() {
    this.getBundles();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {

    getBundles() {
      this.isLoading = true;
      axios
        .get("hot-recharge/telone-bundles")
        .then((response) => {
          this.isLoading = false;
          this.teloneBundles = response.data;
          this.bundleTypes.push(response.data[0].descriptionField);

          this.filterBundles();
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    filterBundles() {
      //let bundles = [];
      this.bundleType =
        this.bundleTypes.length > 0 ? this.bundleTypes[0] : null;
      // if (bundleType) {
      //   this.bundleType = bundleType;

      //   if (this.teloneBundles.hasOwnProperty(bundleType)) {
      //     bundles = this.teloneBundles[bundleType];
      //   }
      // }
      this.selectedBundleId = this.teloneBundles[0].productIdField;
      this.selectedBundleOb = this.teloneBundles[0];
      //this.bundleType = this.bundleType;
      //this.bundles = bundles;
    },

    selectedBundle() {
      // console.log(dataBundle);
      this.teloneBundles.forEach((bundle) => {
        if (bundle.productIdField == this.selectedBundleId) {
          this.selectedBundleOb = bundle;
        }
      });
    },
  },
};
</script>

<style scoped></style>
